var Home = function() {

    this.init = function() {

        var self = this;

        $('.mo-slider').slick({
            dots: true,
            nextArrow: '<button type="button" class="slick-next"><i class="fa fa-angle-right" aria-hidden="true"></i></button>',
            prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-angle-left" aria-hidden="true"></i></button>'
        });

    };

    return this.init();
};
