var Header = function() {

    this.init = function() {

        var self = this;

        // search
        $(document).on('click',  function() {
            $('.js-btn-search').removeClass('-open');
            $('.js-btn-sm').parent().removeClass('-opened');
        });

        $('.js-btn-search').on('click',  function(event) {
            event.stopPropagation();
        });

        $('.js-btn-search > a').on('click',  function(event) {
            event.stopPropagation();

            $(this).parent().toggleClass('-open');
            $('.search-form .search-wrapper .input-text').focus();

            if( window.matchMedia("(max-width: 991px)").matches ) {
                if($(this).parent().hasClass('-open')) {
                    closeSupportMenus();
                    closeMainMenu();

                    $('.backdrop').addClass('fade show');
                } else {
                    $('.backdrop').removeClass('fade show');
                }
            }
        });

        $('.js-btn-sm').on('click', function(event) {
            event.stopPropagation();

            if( window.matchMedia("(max-width: 991px)").matches ) {

                closeSupportMenus($(this).parent('.item'));

                $(this).attr('href', 'javascript: void(0);');

                // $('.backdrop').toggleClass('fade show');
                // $('.js-btn-sm').parent().removeClass('-opened');
                $(this).parent('.item').toggleClass('-opened');

                // Fecha menu
                $('body').removeClass('-open');

                //Fecha a busca
                closeSearchBox();

                // console.log( event.target );
                // console.log( $('.js-btn-sm').parent() );
            }

        });

        function closeSupportMenus($except) {
            if( window.matchMedia("(max-width: 991px)").matches ) {
                if($except) {
                    $('.secondary-menu .item.-opened').not($except).removeClass('-opened');
                } else {
                    $('.secondary-menu .item.-opened').removeClass('-opened');
                }
                $('.backdrop').removeClass('fade show');
            }
        }

        function closeMainMenu() {
            $('body').removeClass('-open');
        }

        function closeSearchBox(){
            $('.js-btn-search').removeClass('-open');
            $('.backdrop').removeClass('fade show');
        }

        // MENU

        $('.btn-hamburger').on('click', function(event) {
            event.preventDefault();

            $('body').toggleClass('-open');
            $('.backdrop').toggleClass('fade show');
        });

        $(document).on('click', '.backdrop',  function() {

            $('.backdrop').toggleClass('fade show');

            if( $('body').hasClass('-open') )
                $('body').toggleClass('-open');

        });

        //Abre e Fecha somente mobile
        /*var isMobile = (window.innerWidth <= 991);

        if(isMobile) {
            //Defini todos os menus como fechados no load
            $('.menu-item-has-children').removeClass('js-open').find('.sub-menu').slideUp();
        }

        $('.menu-item-has-children > a').on('click', function(e) {
            if(isMobile) {
                e.preventDefault();

                var $parent = $(this).closest('.menu-item-has-children'),
                    $submenu = $parent.find('.sub-menu'),
                    isOpen = $parent.hasClass('js-open');

                if(!isOpen) {
                    $('.menu-item-has-children').removeClass('js-open').find('.sub-menu').slideUp();
                }

                $parent.toggleClass('js-open');
                $submenu.slideToggle();
            }
        });*/

    };

    return this.init();
};
