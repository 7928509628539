var Products = function() {

    this.init = function() {

        var self = this;

        $('.page-products .products-list .slides').slick({
            dots: false,
            infinite: false,
            appendArrows: $('.page-products .products-list .arrows-wrapper'),
            nextArrow: '<button type="button" class="slick-next"><i class="fa fa-angle-right" aria-hidden="true"></i></button>',
            prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-angle-left" aria-hidden="true"></i></button>',
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        // TO DO
                    }
                }
            ]
        });

    };

    return this.init();
};
