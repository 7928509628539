var Forms = function() {

    var self = this;

    this.init = function() {

        $.addAjaxResponse('default', function(json){
            if( json.status == 1 ) {
               $(this).closest('.js-form-wrapper').removeClass('-sending').addClass('-done');
               self.setButton(this);
            } else {
                $(this).closest('.js-form-wrapper').removeClass('-sending');
                self.setButton(this);
            }
        });

        $('.js-form').formProc({
            onStart: function(form) {
                $(form).find('.field-error').removeClass('field-error');
            },
            onError: function(input, form) {
                $(input).closest('.field-group').addClass('field-error');
            },
            onEnd: function(form, valid) {
                if(valid) {
                    $(form).closest('.js-form-wrapper').addClass('-sending');

                    self.setSendingButton(form);
                }
            }
        });
    };

    this.setSendingButton = function(form) {
        var $btn = $(form).find('button[type="submit"]'),
            lbl = $btn.data('sending');

        if(lbl) {
            $btn.html(lbl);
        }
    };

    this.setButton = function(form) {
        var $btn = $(form).find('button[type="submit"]'),
            lbl = $btn.data('label');

        if(lbl) {
            $btn.html(lbl);
        }
    };

    this.init();
};
