var Conteudos = function(){

    var self = this;

    this.init = function(){
        /*
            <section class="contents-publications js-content-parent" data-filter="<?php echo $cat['filter']; ?>" data-page="1" data-max-page="<?php echo $postQuery->max_num_pages; ?>" data-url="<?php echo $ajax['url']; ?>" data-action="contentpage">
            <input type="checkbox" id="exclusive" name="exclusive" value="exclusivo" class="js-content-filter">
            <input type="checkbox" id="free" name="free" value="gratuitos" class="js-content-filter">
            js-load-more
        */


        $('.js-content-parent').on('click', '.js-load-more', function(e){
            e.preventDefault();

            var $parent = $(this).closest('.js-content-parent'),
                filter = $parent.data('filter'),
                page = $parent.data('page'),
                maxPage = $parent.data('max-page'),
                url = $parent.data('url'),
                action = $parent.data('action');


            page++;

            if(page >= maxPage) {
                $parent.find('.js-load-more').remove();
            }

            $.post(url, { filter: filter, page: page, action: action }, function(html){
                $parent.find('.js-content-wrapper').append(html);

                self.applyViewFilter($parent);
            });
        });

        $('.js-content-filter').on('change', function(){
            var $parent = $(this).closest('.js-content-parent');

            self.applyViewFilter($parent);
        });
    };

    this.applyViewFilter = function($parent) {
        var checkExclusivos = $parent.find('.js-content-filter').filter('[name="exclusive"]').prop('checked'),
            checkFree = $parent.find('.js-content-filter').filter('[name="free"]').prop('checked');

        $parent.find('.col-card').hide();

        if((checkExclusivos && checkFree) || (!checkExclusivos && !checkFree)) {
             $parent.find('.col-card').show();
        } else if(checkExclusivos) {
            $parent.find('.-locked').parent().show();
        } else if(checkFree) {
            $parent.find('.-unlocked').parent().show();
        }
    };

    return this.init();
};
