var Sticky = function() {

    var self = this;

    var CLASS_STICKY_MOBILE = 'is-mobile';
    var CLASS_AUTOHIDE_HIDDEN = 'is-hidden';

    var doc = $(document);
    var sticky = $('.js-sticky');
    var stickyContainer = $('.js-sticky-container');
    var stickyContainerWidth = stickyContainer.innerWidth();

    var autohide = $('.js-autohide');
    var autohideHeight = autohide.innerHeight();
    var scrolling = false;
    var previousTop = 0;
    var scrollDelta = 12;
    var scrollOffset = autohideHeight;

    this.init = function() {

        self.stickyToggleClass();

        $(window).on('scroll touchmove', function() {

            self.stickyToggleClass();

            if (!scrolling) {
                scrolling = true;
                (!window.requestAnimationFrame) ? setTimeout(self.autoHide, 0): requestAnimationFrame(self.autoHide);
            }

        });

    };

    this.stickyToggleClass = function() {

        var currentTop = doc.scrollTop();

        if (stickyContainerWidth < '992') {
            sticky.addClass(CLASS_STICKY_MOBILE);
        } else {
            sticky.removeClass(CLASS_STICKY_MOBILE);
        }

    };

    this.autoHide = function() {
        var currentTop = $(window).scrollTop();
        self.checkAutoHide(currentTop);
        previousTop = currentTop;
        scrolling = false;
    };

    this.checkAutoHide = function(currentTop) {
        if (previousTop - currentTop > scrollDelta) {
            autohide.removeClass(CLASS_AUTOHIDE_HIDDEN);
        } else if (currentTop - previousTop > scrollDelta && currentTop > scrollOffset) {
            autohide.addClass(CLASS_AUTOHIDE_HIDDEN);
        }
    };

    return this.init();
}
