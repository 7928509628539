(function ($) {
  'use strict';

  $(document).ready(function(){

    if( $('.page-home').length )
      new Home();

    if( $('.page-products').length )
        new Products();

    if( $('.page-contents-publications').length )
        new Conteudos();

    new Header();

    new Forms();

    new Sticky();

    //Máscaras
    var maskBehavior = function (val) {
     return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
    },
    options = {onKeyPress: function(val, e, field, options) {
     field.mask(maskBehavior.apply({}, arguments), options);
     }
    };

    $('.js-mask-phone, #billing_phone').mask(maskBehavior, options);
    $('.js-mask-cpf').mask('000.000.000-00', {reverse: true});
    $('.js-mask-cnpj').mask('00.000.000/0000-00', {reverse: true});
    $('.js-mask-cep, #billing_postcode').mask('00000-000');
    $('.js-mask-money').mask('000.000.000.000.000,00', {reverse: true});


    // ----
    /*$('img.svg').each(function(){
        var $img = $(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');

        $.get(imgURL, function(data) {
            var $svg = $(data).find('svg');

            if(typeof imgID !== 'undefined') {
                $svg = $svg.attr('id', imgID);
            }

            if(typeof imgClass !== 'undefined') {
                $svg = $svg.attr('class', imgClass+' replaced-svg');
            }

            $svg = $svg.removeAttr('xmlns:a');

            if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'));
            }

            $img.replaceWith($svg);

        }, 'xml');

    });*/

  });

})(jQuery);
